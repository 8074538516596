@font-face{
    font-family: inter;
    src: url(./assets/fonts/Inter/static/Inter-Regular.ttf);
}

@font-face{
    font-family: inter-medium;
    src: url(./assets/fonts/Inter/static/Inter-Medium.ttf);
}

html{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body{
    width: 100%;
    height: 100%;
    margin: 0;
    direction:ltr;
    font-variant-numeric: tabular-nums;
    > #root{
        width: 100%;
        height: 100%;
    }
    *{
        font-family: inter, serif;
        -webkit-tap-highlight-color: transparent;
        box-sizing: border-box;
    }
}

.customZIndexTooltip{
    z-index: 9;
}

.calendarTimeSlotTooltip{
    z-index: 15;
}

.bookingTimeSlotTooltip{
    z-index: 14;
}

.ant-message{
    z-index: 20000;
}
